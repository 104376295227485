import Container from 'react-bootstrap/Container';

function SkeddaCalendar() {
    return (
        <Container>
            <iframe
                title="Skedda Booking Calendar"
                src="https://nexusgolf.skedda.com/booking?embedded=false"
                style={{ width: '100%', height: '1000px', border: 'none' }}
            ></iframe>
        </Container>
    );
}

export default SkeddaCalendar;
