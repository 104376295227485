import React from 'react';
import Hero from '../components/hero';
import About from '../components/about';
import Services from '../components/services';
import BayDetails from '../components/bay_details';
import HowTo from '../components/how_to';
import Header from '../components/header';
import Footer from '../components/footer';
import PromoBanner from '../components/promo_banner';
import { useContentfulRequest } from '../utilities';

const query = `{
  homePageCollection(limit: 1) {
    items {
      heroSubtext
      about {
        json
        links {
          entries {
            inline {
              sys {
                id
              }
            }
          }
        }
      }
      baysCollection {
        items {
          title
          subtitle
          description
          features
          rate
          strikethroughRate
          ratePrefix
          bayNumbers
          imageFilename
        }
      }
      servicesCollection {
        items {
          title
          description
          iconStyle
        }
      }
      address
    }
  }
}`;

const Home = () => {
    const { isLoaded, content } = useContentfulRequest(query);

    if (!isLoaded) {
        return 'Loading...';
    }

    const {
        homePageCollection: {
            items: [
                {
                    about,
                    baysCollection: { items: bays },
                    servicesCollection: { items: services },
                },
            ],
        },
    } = content;

    return (
        <>
            <Header />
            <main id="home">
                <Hero />
                <PromoBanner className="d-md-none" />
                <Services backgroundCss={'bg-gray'} services={services} />
                <About backgroundCss={'bg-green-gradient'} about={about} />
                <BayDetails backgroundCss={'bg-white'} bays={bays} />
                <HowTo background={'bg-green-gradient'} />
            </main>
            <Footer background={'bg-white'} />
        </>
    );
};

export default Home;
