import React from 'react';

import Accordion from 'react-bootstrap/Accordion';
import { useContentfulRequest } from '../utilities';
import MarkdownView from 'react-showdown';
import PageTemplate from '../components/pageTemplate';

const query = `query {
  pageCollection (where: {pageId: "faq"}, limit: 1) {
    items {
      pageId
      pageTitle
      contentCollection {
        items {
          ... on QaGroup {          
            groupTitle
            questionsCollection {
              items {
                question
                answer
              }
            }
          }
        }
      }
    }
  }
}`;

function Faq() {
    const { isLoaded, content } = useContentfulRequest(query);

    if (!isLoaded) {
        return 'Loading...';
    }

    const {
        pageCollection: {
            items: [
                {
                    pageId,
                    pageTitle,
                    contentCollection: { items: qaGroup },
                },
            ],
        },
    } = content;

    return (
        <PageTemplate showHeader={true} pageId={pageId} useContainer={true} pageTitle={pageTitle}>
            {qaGroup.map(({ groupTitle, questionsCollection: { items } }, i) => (
                <div key={i}>
                    <h2>{groupTitle}</h2>
                    <Accordion defaultActiveKey="0" flush className="mb-4">
                        {items.map(({ question, answer }, i) => (
                            <Accordion.Item eventKey={i}>
                                <Accordion.Header>{question}</Accordion.Header>
                                <Accordion.Body>
                                    <MarkdownView markdown={answer} />
                                </Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                </div>
            ))}
        </PageTemplate>
    );
}

export default Faq;
