import Container from 'react-bootstrap/Container';
import logo from '../assets/images/nexus-logo-white.svg';
import text_logo from '../assets/images/nexus-logo-nav-white.svg';
import Button from 'react-bootstrap/Button';
import React, { useContext } from 'react';
import { PageContext } from '../app';
import PromoBanner from '../components/promo_banner';
function Hero() {
    const {
        strings: { heroData },
    } = useContext(PageContext);
    return (
        <div id="hero" className="home">
            <div className="sliding-background"></div>
            <Container className="m-0 mw-100 w-100">
                <PromoBanner className="d-none d-md-block" />
                <div className="hero-content d-flex flex-column text-center justify-content-center">
                    <div className="logo-slogan">
                        <img src={logo} alt="" className="img-fluid d-md-none logo" />
                        <img src={text_logo} alt="" className="img-fluid logo d-none d-md-block" />
                        <div className="fade-divider"></div>
                        <div className="slogan">{heroData.title}</div>
                    </div>{' '}
                    <Button
                        variant="primary"
                        href="/book"
                        className="text-uppercase font-weight-bold hero-cta"
                    >
                        Book Now
                    </Button>
                    <a
                        className="hero-learn-more text-decoration-none secondary-color d-none d-md-block"
                        href="#services"
                    >
                        <p className="">Scroll to learn more.</p>
                        <i className="fa-solid fa-arrow-down-long"></i>
                    </a>
                </div>
            </Container>
        </div>
    );
}

export default Hero;
