import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { useContentfulRequest } from '../utilities';
import PageTemplate from '../components/pageTemplate';

const query = `{
  pageCollection(where: {pageId: "howItWorks"}, limit: 1) {
    items {
      pageId
      pageTitle
      contentCollection(limit: 1) {
        items {
          ... on BlockText {
            richText {
              json
              links {
                assets {
                  block {
                    title
                    url
                    sys {
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}`;

function HowItWorks() {
    const { isLoaded, content } = useContentfulRequest(query);

    if (!isLoaded) {
        return 'Loading...';
    }

    const {
        pageCollection: {
            items: [
                {
                    // pageId,
                    pageTitle,
                    contentCollection: {
                        items: [
                            {
                                richText: { json: document, links },
                            },
                        ],
                    },
                },
            ],
        },
    } = content;

    const richTextOptions = {
        renderNode: {
            [BLOCKS.EMBEDDED_ASSET]: node => {
                const img = links.assets.block.find(i => i.sys.id === node.data.target.sys.id);
                return <img src={img?.url} alt={img?.title} className="how-it-works-img" />;
            },
        },
    };
    return (
        <PageTemplate showHeader={true} pageId={'how-it-works'} useContainer={true} pageTitle={pageTitle}>
            {documentToReactComponents(document, richTextOptions)}
        </PageTemplate>
    );
}

export default HowItWorks;
