import React from 'react';
import SkeddaCalendar from '../components/skedda_calendar';
import PageTemplate from '../components/pageTemplate';

const Bookings = () => {
    return (
        <PageTemplate showHeader={true} pageId={'bookings'} useContainer={false}>
            <SkeddaCalendar />
        </PageTemplate>
    );
};

export default Bookings;
