import React from 'react';

import PageTemplate from '../components/pageTemplate';
import { useContentfulRequest } from '../utilities';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import MarkdownView from 'react-showdown';
import { useNavigate } from 'react-router-dom';

const query = `query {
  pageCollection (where: {pageId: "instructors"}, limit: 1) {
    items {
      pageId
      pageTitle
      emptyMessage
      description
      contentCollection {
        items {
          ... on InstructorCard {          
            lastName
            firstName
image {
title
						  description
						  contentType
						  fileName
						  size
						  url
						  width
						  height
						}
            description
          }
        }
      }
    }
  }
}`;

export default function Instructors() {
    const { isLoaded, content } = useContentfulRequest(query);
    const navigate = useNavigate();

    if (!isLoaded) {
        return 'Loading...';
    }

    if (!content || !content.pageCollection.items.length) {
        return navigate('/');
    }

    const {
        pageCollection: {
            items: [
                {
                    pageId,
                    pageTitle,
                    emptyMessage,
                    description,
                    contentCollection: { items: instructors },
                },
            ],
        },
    } = content;

    return (
        <PageTemplate showHeader={true} pageId={pageId} useContainer={true} pageTitle={pageTitle}>
            <p className="mb-3">
                <MarkdownView markdown={description} />
            </p>
            {instructors.length ? (
                instructors.map((instructor, i) => <InstructorCard instructorData={instructor} />)
            ) : (
                <p>{emptyMessage}</p>
            )}
        </PageTemplate>
    );
}

function InstructorCard({ instructorData: i }) {
    return (
        <Card className="border-0 p-1">
            <Row>
                <Col md={4}>
                    <Image className="img-fluid w-100 object-fit-cover" src={i.image.url} />
                </Col>
                <Col md={8}>
                    <Card.Body>
                        <Card.Title className="mb-0">
                            {i.lastName},&nbsp;{i.firstName}
                        </Card.Title>
                        <p>
                            <MarkdownView markdown={i.description} />
                        </p>
                    </Card.Body>
                </Col>
            </Row>
        </Card>
    );
}
