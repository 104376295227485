import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import React from 'react';
function Services({ backgroundCss, services }) {
    return (
        <div id="services" className={`paddsection ${backgroundCss}`}>
            <Container>
                <h1 className="text-center">
                    <span>Experience the difference.</span>
                </h1>
                <Row className="align-items-stretch flex-md-row">
                    {services.map((service, i) => (
                        <Col md={6} lg={4} className="mb-4" key={i}>
                            <ServiceItem
                                key={i}
                                name={service.title}
                                description={service.description}
                                iconStyle={service.iconStyle}
                            />
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    );
}

function ServiceItem(props) {
    return (
        <Row className="text-center m-3">
            <i className={`mb-3 ${props.iconStyle} custom-icon`}></i>
            <h3>{props.name}</h3>
            <p className="mb-0">{props.description}</p>
        </Row>
    );
}

export default Services;
