import React from 'react';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { useContentfulRequest } from '../utilities';
import PageTemplate from '../components/pageTemplate';

const query = `{
  pageCollection(where: {pageId: "privacy"}, limit: 1) {
    items {
      pageId
      pageTitle
      contentCollection (limit:1) {
        items {
          ... on BlockText {
            richText {
              json
              links {
                entries {
                  inline {
                    sys {
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}`;

function PrivacyPolicy() {
    const { isLoaded, content } = useContentfulRequest(query);

    if (!isLoaded) {
        return 'Loading...';
    }

    const {
        pageCollection: {
            items: [
                {
                    pageId,
                    pageTitle,
                    contentCollection: {
                        items: [
                            {
                                richText: { json: document },
                            },
                        ],
                    },
                },
            ],
        },
    } = content;

    return (
        <PageTemplate showHeader={true} pageId={pageId} useContainer={true} pageTitle={pageTitle}>
            {documentToReactComponents(document)}
        </PageTemplate>
    );
}

export default PrivacyPolicy;
