import Container from 'react-bootstrap/Container';
import React, { useContext } from 'react';
import { PageContext } from '../app';

function HowTo(props) {
    const {
        strings: { howToSteps },
    } = useContext(PageContext);
    return (
        <div id="howTo" className={`text-center paddsection ${props.background}`}>
            <Container className="py-2">
                <a href="/how-it-works">
                    <h1>
                        <span>How It Works</span>
                    </h1>
                </a>
                <ul
                    id="howToList"
                    className="list-group list-group-horizontal-md justify-content-center pb-5"
                >
                    {howToSteps.map((item, i) => (
                        <HowToItem data={item} key={i} />
                    ))}
                </ul>
                <div>
                    <a className="readmore-link text-uppercase" href="/how-it-works">
                        <span>Read More.</span>
                    </a>
                </div>
            </Container>
        </div>
    );
}

function HowToItem(props) {
    return (
        <li className="list-group-item bg-transparent">
            <div>
                <i className={`fa-2xl ${props.data.icon} mb-4 primary-color`}></i>
                <h5 className="primary-color">Step {props.data.step_no}</h5>
                <p className="fs-4">{props.data.title}</p>
            </div>
        </li>
    );
}

export default HowTo;
