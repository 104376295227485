import React, { createContext, useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './assets/styles/style.scss';
import './assets/js/main.js';
import Home from './pages/home';
import Bookings from './pages/book';
import Waiver from './pages/waiver';
import Terms from './pages/terms';
import TermsMembership from './pages/terms_membership';
import PrivacyPolicy from './pages/privacy';
import HowItWorks from './pages/how-it-works';
import Rules from './pages/rules';
import Faq from './pages/faq';
import Packages from './pages/packages';
import Instructions from './pages/instructions';
import Instructors from './pages/instructors';
import strings from './assets/documents/strings.json';
// import firebase from 'firebase/compat/app';
// import * as firebaseui from 'firebaseui';
// import 'firebaseui/dist/firebaseui.css';

export const PageContext = createContext(null);

function App() {
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        const onScroll = () => setOffset(window.scrollY);

        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    return (
        <Router>
            <PageContext.Provider value={{ offset: offset, setOffset: setOffset, strings: strings }}>
                <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route path="/book" element={<Bookings />} />
                    <Route path="/rules" element={<Rules />} />
                    <Route path="/instructions" element={<Instructions />} />
                    <Route path="/terms_membership" element={<TermsMembership />} />
                    <Route path="/packages" element={<Packages />} />
                    <Route path="/privacy" element={<PrivacyPolicy />} />
                    <Route path="/faq" element={<Faq />} />
                    <Route path="/waiver" element={<Waiver />} />
                    <Route path="/terms" element={<Terms />} />
                    <Route path="/how-it-works" element={<HowItWorks />} />
                    <Route path="/instructors" element={<Instructors />} />
                </Routes>
            </PageContext.Provider>
        </Router>
    );
}

export default App;
