import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

function About({ backgroundCss, about: { json: document } }) {
    return (
        <div id="about" className={`paddsection ${backgroundCss}`}>
            <Container>
                <Row>
                    <Col className="text-justify" md={12}>
                        {documentToReactComponents(document)}
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default About;
