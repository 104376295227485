import React from 'react';
import PageTemplate from '../components/pageTemplate';

export default function Instructions() {
    return (
        <PageTemplate showHeader={true} pageId={'instructions'} useContainer={true} pageTitle={'How to Play'}>
            <ul className="list-unstyled">
                <li>
                    <h3>How to Start a Practice Range</h3>
                    <iframe
                        title="How to Start Practice Range"
                        src="https://docs.google.com/document/d/1k_XBbtGEogxzfJKPi7r37H2V3oeeQGrZKS2fgqUIzcE/pub?embedded=true"
                        width="100%"
                        height="500"
                    ></iframe>
                </li>
                <li>
                    <h3 className="mt-3">How to Start a Course</h3>
                    <iframe
                        title="How to Start a Course"
                        src="https://docs.google.com/document/d/1nIJVLOqxrOEheyQVs_oDWKn9YDrJ20NT0SiPHTCQaMo/pub?embedded=true"
                        width="100%"
                        height="500"
                    ></iframe>
                </li>
            </ul>
        </PageTemplate>
    );
}
