import Container from 'react-bootstrap/Container';
import React from 'react';
import { useContentfulRequest } from '../utilities';
import Contact from '../components/contact';
const query = `query {
  footerCollection(limit: 1){
    items{
    copyrights
    footerLinksCollection {
      items {
            text
        url
      }
    }
    }
  }
}`;

function Footer(props) {
    const { isLoaded, content } = useContentfulRequest(query);

    if (!isLoaded) {
        return 'Loading...';
    }

    const {
        footerCollection: {
            items: [
                {
                    copyrights,
                    footerLinksCollection: { items: links },
                },
            ],
        },
    } = content;

    return (
        <>
            <Contact background={'bg-gray'} />
            <div id="footer" className={`text-center ${props.background}`}>
                <Container>
                    <div id="disclaimers">
                        <p className="text-uppercase">
                            {links.map((l, i) => (
                                <a href={l.url} key={i} className="text-decoration-none">
                                    {l.text}
                                </a>
                            ))}
                        </p>
                    </div>
                    <p>{copyrights}</p>
                </Container>
            </div>
        </>
    );
}

export default Footer;
