import React from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import Container from 'react-bootstrap/Container';

const PageTemplate = props => {
    const { showHeader, pageId, useContainer, pageTitle, children } = props;
    return (
        <>
            <Header show={showHeader} />
            <main id={pageId} className={'pb-5 ' + (showHeader ? 'header-gap' : '')}>
                {useContainer ? (
                    <Container>
                        {pageTitle && <h1 className="my-5">{pageTitle}</h1>}
                        {children}
                    </Container>
                ) : (
                    children
                )}
            </main>
            <Footer background={'bg-white'} />
        </>
    );
};

export default PageTemplate;
