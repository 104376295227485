import Container from 'react-bootstrap/Container';
import { useContentfulRequest } from '../utilities';

const query = `{
  promoBannerCollection(limit: 1) {
    items {
			message
      url
      color
    }
  }
}`;

function PromoBanner(props) {
    const { isLoaded, content } = useContentfulRequest(query);

    if (!isLoaded) {
        return 'Loading...';
    }

    if (!content.promoBannerCollection.items.length) {
        return;
    }

    const {
        promoBannerCollection: {
            items: [{ message, url, color }],
        },
    } = content;

    return (
        <div id="promo-banner" className={`p-3 text-center bg-${color} ${props.className}`}>
            <Container>
                <a href={url} className="text-decoration-none">
                    {message}
                    <i className="fa-solid fa-arrow-right-long"></i>
                </a>
            </Container>
        </div>
    );
}

export default PromoBanner;
