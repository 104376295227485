import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import React from 'react';
import { useContentfulRequest } from '../utilities';
import MarkdownView from 'react-showdown';
import PageTemplate from '../components/pageTemplate';

const query = `{
  pageCollection(where: {pageId: "packages"}, limit: 1) {
    items {
      pageId
      pageTitle
      contentCollection (limit:3) {
        items {
          ... on MembershipPackageCard {
            name
            price
            unit
            imageFilename
            description
            cardStyle
            buyButtonId
            terms
            features
          }
        }
      }
    }
  }
}`;

function Packages() {
    const { isLoaded, content } = useContentfulRequest(query);

    if (!isLoaded) {
        return 'Loading...';
    }

    const {
        pageCollection: {
            items: [
                {
                    pageId,
                    pageTitle,
                    contentCollection: { items: packages },
                },
            ],
        },
    } = content;

    return (
        <PageTemplate showHeader={true} pageId={pageId} useContainer={true} pageTitle={pageTitle}>
            {packages.length ? (
                <Row>
                    {packages.map(p => (
                        <Col md={4} className="mb-3">
                            <PackageCard packageData={p} />
                            <PackageDescription packageData={p} />
                        </Col>
                    ))}
                </Row>
            ) : (
                <p>
                    Exciting things are on the way! While we currently don't have any packages or memberships
                    available, be sure to check back soon for the latest updates and offers!
                </p>
            )}
        </PageTemplate>
    );
}

function PackageCard({ packageData: p }) {
    return (
        <Card className={p.cardStyle}>
            <Card.Img variant="top" src={require(`../assets/images/${p.imageFilename}`)} />
            <Card.Body className="text-center">
                <Card.Title className="mb-0">{p.name}</Card.Title>
            </Card.Body>
        </Card>
    );
}

function PackageDescription({ packageData: p }) {
    return (
        <div className="mt-3">
            <p className="pb-3">
                <MarkdownView markdown={p.description} />
            </p>
            <ul>
                <MarkdownView markdown={p.features} />
            </ul>
            <p className="text-center">
                <span className="package-price">${p.price}</span>/{p.unit} + HST
            </p>
            <div className="text-center">
                <stripe-buy-button
                    buy-button-id={p.buyButtonId}
                    publishable-key="pk_live_51P5AiNP960tlgwiouyUEY9S23It6h6StbhU0ozDhsKsWFxVW7SnkAo7XVtETgHPg1JVdKGD9ZcPq4INDC5U5FwLQ00UjHr5kfN"
                ></stripe-buy-button>
            </div>
            {p.terms && (
                <p className="terms pt-3">
                    <MarkdownView markdown={p.terms} />
                </p>
            )}
        </div>
    );
}
export default Packages;
